<template>

  <div class="h-screen flex w-full bg-img">
    <div class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img src="@/assets/images/logo/flipmoney.png" width="250px" alt="resendVerification" class="mx-auto">
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">Resend verification link</h4>
                  <p>Please enter your email address and we'll send you a new verification link.</p>
                </div>
                <br />
                <vs-input
                  v-validate="'required|email|min:3'"
                  data-vv-validate-on="blur"
                  name="email"
                  icon-no-border
                  icon="icon icon-user"
                  icon-pack="feather"
                  label-placeholder="Email"
                  v-model="user.email"
                  class="w-full mb-8"/>
                <span class="text-danger text-sm">{{ errors.first('email') }}</span>
               <br>
                <vs-button type="border" to="/admin/login" class="px-4 w-full md:w-auto">Back To Login</vs-button>
                <vs-button @click="resendVerificationLink" class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-0">Resend verification link</vs-button>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  export default {
    data() {
      return {
        user: {
          email: '',
        },
        showDismissibleAlert: false,
        message: '',
        loginError: ''
      }
    },
    computed: {
      validateForm() {
        return !this.errors.any() && this.email != '';
      },
    },
    methods: {
      ...mapActions('auth', ['resendVerificationMail']),
      resendVerificationLink() {
        const payload = {
          userDetail: this.user
        }
        this.$validator.validateAll().then( result => {
          if(result) {
            this.$vs.loading();
            this.resendVerificationMail(payload)
              .then((response) => {
                this.showDismissibleAlert = true
                this.message = response.data.message
                this.$vs.loading.close()
                this.$vs.notify({
                  title: 'Message',
                  text: response.data.message,
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'green',


                })
              })
              .catch((error) => {
                this.showErrorDismissibleAlert = true
                this.message = error.message
                this.$vs.loading.close()
                this.$vs.notify({
                  title: 'Error',
                  text: error.message,
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'danger',


                })
              })
          }
        })
      }
    }
  }
</script>
